import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const PeaksuiteToolsDesc = () => (
  <section className="peaksuite-products-wrapper py-sm-1">
    <div className="container">
      <div className="row">
        <div className="mx-auto col-md-8 text-center mb-1 text-left-mobile pt-1">
          <h2 className="nospace-titlehead">
            peakSUITE Offers Modular Systems That Rapidly Streamline Entire
            Processes
          </h2>
        </div>
      </div>
      <div
        className="row flex-lg-row flex-md-row flex-column-reverse mt-5"
        id="peaksuite-document-generator"
        style={{ scrollMarginTop: "10rem" }}
      >
        {/* <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center mt-lg-0 mt-md-0 mt-5 text-left-mobile">
          <div id="peak1" />
          <h3 className="nospace-titlehead">peakSUITE Chat Tool</h3>
          <p>
            Empower your team to send messages and notifications to anyone
            within Quickbase for rapid communication. Share room changes and
            updates within seconds right on the Quickbase platform. No changing
            apps. No switching platforms.
          </p>
          <p>
            Chat Tool Lets you add hubs and segment them into teams within your
            organization, all while tracking and staying ahead of changes. Plus,
            you get the insights needed to direct your staff.
          </p>
          <p>
            Chat Tool lets you save messages, invite others to hubs, establish
            user-based access, and experience a range of customizable options.
            Logins are time-sensitive and remain valid for up to 8 hours. All
            messages stay on the platform, keeping your communications secure.
          </p>
          <Link
            className="btn btn-primary btn-arrow"
            to="/peaksuite/peaksuite-chat-tool/"
          >
            Learn More
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 align-self-center">
          <StaticImage
            placeholder="blurred"
            imgClassName="img-fluid w-100"
            src="../../../static/assets/peaksuite-Chat Tool.png"
            alt="peakSuite Chat Tool"
            layout="constrained"
            loading="lazy"
          />
        </div> */}
      </div>

      <div className="row my-5">
        <div className="col-lg-6 col-md-6 align-self-center">
          <StaticImage
            placeholder="blurred"
            imgClassName="img-fluid w-100"
            src="../../../static/assets/productLogos/peaksuite-DU.png"
            alt="peakSuite Document Generator"
            layout="constrained"
            loading="lazy"
          />
        </div>
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center mt-lg-0 mt-md-0 mt-5 text-left-mobile">
          <div id="peak2" />
          <h3>peakSUITE Document Generator</h3>
          <p>
            Easily transfer data from any system or platform into a base
            template that you can package into any file (such as a .doc or .pdf)
            and send to storage or email. It’s the easiest way to send documents
            to those who need them. Quick. Accessible. Effortless.
          </p>
          <Link
            className="btn btn-primary btn-arrow"
            to="/peaksuite/peaksuite-docgenerator/"
          >
            Learn More
          </Link>
          {/* <Link
                    className="btn btn-primary btn-arrow"
                    to="/peaksuite/peaksuite-echo/"
                  >
                    Learn More
                  </Link> */}
        </div>
      </div>
      <div className="row flex-lg-row flex-md-row flex-column-reverse">
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center mt-lg-0 mt-md-0 mt-5 text-left-mobile pb-5">
          <div id="peak3" />
          <h3>peakSUITE Multi File Uploader</h3>
          <p>
            Users can only upload one document at a time in Quickbase,
            currently. Multi-File Uploader change that. Our tool allows users to
            select multiple documents at a time and upload them to any record.
            Plus, you can upload or drag-and-drop files, rename document titles,
            and view the file type/size before uploading. The time it will save
            your team is incredible.
          </p>
          <Link
            className="btn btn-primary btn-arrow"
            to="/peaksuite/peaksuite-multi-file-uploader/"
          >
            Learn More
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 align-self-center text-center">
          <StaticImage
            placeholder="blurred"
            imgClassName="img-fluid w-100"
            src="../../../static/assets/productLogos/PS_multi-file-uploader.png"
            alt="peakSuite Multi File Uploader"
            layout="constrained"
            loading="lazy"
          />
        </div>
      </div>
      <div className="row my-5">
        <div className="col-lg-6 col-md-6 align-self-center">
          <StaticImage
            placeholder="blurred"
            imgClassName="img-fluid w-100"
            src="../../../static/assets/productLogos/PS_compass.png"
            alt="peakSuite Compass"
            layout="constrained"
            loading="lazy"
          />
        </div>
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center mt-lg-0 mt-md-0 mt-5 text-left-mobile">
          <div id="peak4" />
          <h3>peakSUITE Compass</h3>
          <p>
            Compass is a custom-built mapping tool within Quickbase that
            provides your team with a full overview of the core location-based
            processes your business manages.
          </p>
          <p>
            View topics like PROJECTS, CUSTOMERS, or SITES in full-page map
            format with multiple options for searching and categorizing. Easily
            highlight your listed sites on the map for details at a glance. Or,
            open your related records for deeper insights and better visibility.
          </p>
          <Link
            className="btn btn-primary btn-arrow"
            to="/peaksuite/peaksuite-compass/"
          >
            Learn More
          </Link>
        </div>
      </div>

      {/* <div className="row flex-lg-row flex-md-row flex-column-reverse">
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center mt-lg-0 mt-md-0 mt-5 text-left-mobile">
          <div id="peak5" />
          <h3>peakSUITE Calendar</h3>
          <p>
            Access a calendar system created in Quickbase that fits your needs
            and works for you. Simply click on an event and edit it directly on
            the page without leaving the platform or worrying about syncing to
            the correct systems.
          </p>
          <p>
            Merge multiple data sources and confidently integrate a range of
            robust capabilities into your workflows without the major overhaul.
            Plus, you can easily customize your Calendar to reflect your brand.
          </p>
          <p>
            Best of all, Calendar and Chat Tool work together. Create, update,
            delete, and filter events or send messages to your organization. All
            within seconds. All from the same page.
          </p>
          <Link
            className="btn btn-primary btn-arrow"
            to="/peaksuite/peaksuite-calendar/"
          >
            Learn More
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 align-self-center">
          <StaticImage
            placeholder="blurred"
            imgClassName="img-fluid w-100"
            src="../../../static/assets/peaksuite-Calendar.png"
            alt="peakSuite Calendar"
            layout="constrained"
            loading="lazy"
          />
        </div>
      </div> */}
      {/* <div className="row my-5">
        <div className="col-lg-6 col-md-6 align-self-center text-center">
          <StaticImage
            placeholder="blurred"
            imgClassName="img-fluid w-100"
            src="../../../static/assets/PS-Pivot-Table.png"
            alt="peakSuite Pivot Table"
            layout="constrained"
            loading="lazy"
          />
        </div>
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center mt-lg-0 mt-md-0 mt-5 text-left-mobile">
          <div id="peak6" />
          <h3>peakSUITE Pivot Table</h3>
          <p>
            Comparing data sets has never been easier or more customizable with
            Pivot Table. Rather than build out an extensive table in your
            database, Pivot Table provides a fully custom code page that can
            query and organize data into a true pivot format.
          </p>
          <p>
            Compare fields from any table in your database to use as filters.
            Automatically calculate for incoming data. And handle everything
            from simple counts to complex variables all within Pivot Table.
          </p>
          <p>
            The drag and drop functionality makes it easy to compare data.
            Filter options are fully dynamic, updating each time a field is
            updated without having to alter the underlying code. And any data
            layout created in Pivot Table can be exported as an excel document
            with formatting that matches your existing database.
          </p>
          <Link
            className="btn btn-primary btn-arrow"
            to="/peaksuite/peaksuite-pivot-table/"
          >
            Learn More
          </Link>
        </div>
      </div> */}
      {/* <div className="row flex-lg-row flex-md-row flex-column-reverse">
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center mt-lg-0 mt-md-0 mt-5 text-left-mobile">
          <div id="peak7" />
          <h3>peakSUITE Echo</h3>
          <p>
           
            Echo is the Twitter of Quickbase. Post company news. Share sales and
            marketing updates. Motivate your team. All from a single portal
            without the background noise or clutter found in traditional
            business communication platforms.
          </p>
          <p>
           
            Simply choose an announcement type and fill out the form. Once an
            administrator approves the post, Echo delivers the right message to
            the right people at the right time.
          </p>
          <p>
           
            Echo also lets users “like” announcements, set profile pictures, and
            perform other interactive features to keep your team engaged. It’s a
            frictionless way to keep everyone connected to your company informed
            from a single, custom interface on any Quickbase dashboard.
          </p>
          <Link
            className="btn btn-primary btn-arrow"
            to="/peaksuite/peaksuite-echo/"
          >
            Learn More
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 align-self-center">
          <StaticImage
            placeholder="blurred"
            imgClassName="img-fluid w-100"
            src="../../../static/assets/productLogos/PS-echo.png"
            alt="peakSuite Echo"
            layout="constrained"
            loading="lazy"
          />
        </div>
      </div>
      <div className="row my-5">
        <div className="col-lg-6 col-md-6 align-self-center">
          <StaticImage
            placeholder="blurred"
            imgClassName="img-fluid w-100"
            src="../../../static/assets/productLogos/PS_trailhead.png"
            alt="peakSuite Trailhead"
            layout="constrained"
            loading="lazy"
          />
        </div>
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center mt-lg-0 mt-md-0 mt-5 text-left-mobile">
          <div id="peak8" />
          <h3>peakSUITE Trailhead</h3>
          <p>
            Trailhead is a simple, effective way to manage your projects while
            maintaining constant communication with your clients. It eliminates
            scope creep, keeps stakeholders in the loop, and holds every member
            of the project accountable.
          </p>
          <p>
            Track various project deliverables and due dates, guiding each one
            to completion. Easily monitor approaching milestones on the calendar
            interface. Approve, reject, or hold new request milestones for a
            later date or future project from your peakSUITE portal.
          </p>
          <Link
            className="btn btn-primary btn-arrow"
            to="/peaksuite/peaksuite-trailhead/"
          >
            Learn More
          </Link>
        </div>
      </div> */}
      {/* <div className="row flex-lg-row flex-md-row flex-column-reverse">
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center mt-lg-0 mt-md-0 mt-5 text-left-mobile pb-5">
          <div id="peak9" />
          <h3>peakSUITE Basecamp</h3>
          <p>
            Basecamp helps your workforce remain productive and connected while
            working remotely. Guide managers and employees as they assimilate to
            working outside of the office. And keep them connected, ensuring
            productivity remains constant in any market climate.
          </p>
          <p>
            Managers get a real-time overview of work-in-progress, completed
            projects, and outstanding deliverables on their dashboard. Keep your
            team connected and find the gaps where employees need support. It’s
            a simple portal that connects your staff no matter how far apart
            they are.
          </p>
          <Link
            className="btn btn-primary btn-arrow"
            to="/peaksuite/peaksuite-basecamp/"
          >
            Learn More
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 align-self-center text-center">
          <StaticImage
            placeholder="blurred"
            imgClassName="img-fluid w-100"
            src="../../../static/assets/productLogos/basecamp.png"
            alt="peakSuite Pivot-table"
            layout="constrained"
            loading="lazy"
          />
        </div>
        
      </div> */}
    </div>
  </section>
)
export default PeaksuiteToolsDesc
